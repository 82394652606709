<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <div class="mt-8">

        <div class="justify-content-end d-flex mb-4">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="add_attribute"><i
            class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('FORM.ADD_ATTRIBUTE') }}</a>
        </div>

        <!-- Table -->
        <div class="table-container" style="overflow: hidden;">
          <b-table
            id="attribute-table"
            class="table-striped"
            :fields="fields"
            :items="local_item.groups"
            head-variant="light"
            ref="attribute-table"
            :tbody-tr-class="rowClass"
          >
          <template #cell(group_id)="data">

            <AttributeSelectRow 
              :groups="groups"
              :item="data.item"
              @deleted="deleted_row"
              @updated="updated_row"
            />

          </template>
          
          </b-table>

        </div>

      </div>

      <p class="mt-4"><a href="/ml-properties">{{ $t('MEMBER_PROPERTY.ADMINISTER') }} >></a></p>

    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import AttributeGroupSelectButton from '@/view/components/attribute_group_selector/AttributeGroupSelectButton.vue';
import AttributeSelectRow from '@/view/pages/ml/forms/rules/AttributeSelectRow.vue';

export default {

  name: 'FormRuleAttributeEditor',

  props: ['item'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    AttributeGroupSelectButton,
    AttributeSelectRow
  },

  watch: {
    
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {

        this.local_item = { ...this.item };

        if (!this.local_item.groups) {
          this.local_item.groups = [];
        }

        for (let i = 0; i < this.local_item.groups.length; ++i) {
          this.local_item.groups[i].index = i;
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','customer']),
  },

  async mounted() {

    await this.load_items();

    if (this.item) {
      this.local_item = { ...this.item }
    }
    else {
      this.local_item = {};
    }

    if (!this.local_item.groups) {
      this.local_item.groups = [];
    }

    for (let i = 0; i < this.local_item.groups.length; ++i) {
      this.local_item.groups[i].index = i;
    }
  
  },

  methods: {

    add_attribute() {
      this.local_item.groups.push({
        group_id: null,
        prop_id: null
      });

      this.local_item = { ...this.local_item };
    },

    updated_row(item) {
      this.local_item.groups[item.index] = item;

      this.local_item = { ...this.local_item };

      console.log('updated row', this.local_item);
    },

    deleted_row(index) {
      this.local_item.groups.splice(index, 1);

      for (let i = 0; i < this.local_item.groups.length; ++i) {
        this.local_item.groups[i].index = i;
      }

      this.local_item = { ...this.local_item };
    },
    
    async load_items() {
      try {
        const response = await axios.get(`/property_group`);
        this.groups = response.data;
      }
      catch (err) {
        console.error('error loading attributes', err);
      }
    },
    
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.added) {
          return 'bg-success';
        }
      }
      return null;
    },
    
    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      fields: [
        {
          key: 'group_id',
          label: this.$t('FORM.ADDED_ATTRIBUTES'),
          sortable: true,
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
        },
      ],

      groups: [],

      local_item: {},
      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

@import "@/assets/sass/components/forms/_common_modals.scss";

:deep .v-label {
  left: 9px;
  top: 3px;
}

</style>

