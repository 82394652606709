<template>
  <div id="page-files">
    <h5 class="m-4">{{ $t('FORM.FORMS') }}</h5>

    <Confirm
      :title="$t('FORM.DELETE_CONFIRM_TITLE')"
      :message="$t('FORM.DELETE_CONFIRM_MESSAGE')"
      ref="delete-form-confirm"
      type="success"
      @confirm="delete_form_confirmed"
    />

    <FormEditorModal
      :item="selected_item"
      ref="form-editor-modal"
      @updated="form_updated"
      @created="form_created"
    />

    <FormsTable
      :items="items"
      @delete_form_clicked="delete_form_clicked"
      @select_form_clicked="select_form_clicked"
      @create_form_clicked="create_form_clicked"
    />

  </div>
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import FormsTable from '@/view/pages/ml/forms/FormsTable.vue';
import FormEditorModal from '@/view/pages/ml/forms/FormEditorModal.vue';


export default {
  name: 'FormsPage',
  mixins: [ toasts ],
  components: {
    FormsTable,
    FormEditorModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    this.load_forms();
  },
  data() {
    return {
      selected_item: {},
      items: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {

      }
    }
  },
  methods: {

    create_form_clicked() {
      this.selected_item = { 
        texts: {
          success_header: 'Lyckad registrering',
          success_text: 'Tack för att du registrerade dig. Kontrollera inkorgen för att bekräfta din e-postadress.',
          failure_header: 'Registrering misslyckades',
          failure_text: 'Något gick fel. Vänligen försök igen senare.'
        } 
      };

      this.$nextTick(()=>{
        this.$refs['form-editor-modal'].show();
      });
    },

    form_updated(item) {
      const index = this.items.findIndex(i => i.id === item.id);

      if (index > -1) {
        this.items[index] = item;
      }

      this.items = [...this.items];
    },

    form_created(item) {
      this.items.push(item);
    },

    delete_form_clicked(id) {
      this.selected_item.id = id;

      this.$refs['delete-form-confirm'].show();
    },

    async select_form_clicked(id) {
      this.selected_item = this.items.find(item => item.id === id);

      const item = await axios.get(`/form/admin/${id}`);

      this.selected_item = item.data;

      this.$nextTick(()=>{
        this.$refs['form-editor-modal'].show();
      });
    },

    async delete_form_confirmed() {
      
      try {
        const response = await axios.delete(`/form/${this.selected_item.id}`);

        if (response.status === 204) {
          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.DELETED'));

          this.items = this.items.filter(item => item.id !== this.selected_item.id);

          return;
        }
      }
      catch (err) {
        console.error('delete_form_confirmed error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORM.UNABLE_DELETE'));

    },

    async load_forms() {
      
      try {
        const response = await axios.get(`/form`);

        if (response.status === 200) {
          this.items = response.data;

          return;
        }
      }
      catch (err) {
        console.error('load_forms error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORM.UNABLE_LIST'));

    },
  }
};
</script>
